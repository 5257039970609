import { Injectable, inject } from '@angular/core';
import { RequestService } from '@core/services/request/request.service';
import { SignUpBody } from '@core/models/auth.model';
import { ReviewBody, UserApiResponse } from '@core/models-api/user-api.model';
import { VehicleBody } from '@core/models/vehicle.model';
import { Observable, Subject } from 'rxjs';
import { UserUpdate } from '@core/models/user.model';
import { WorkshopLandingPageApiResponse } from '@core/models-api/workshop-api.model';
import { StockItemBody, StockItemTransactionBody } from '@core/models/workshop.model';

@Injectable({ providedIn: 'root' })
export class ApiWorkshopService {

  /** Subject to force update client data */
  public shouldUpdateClient$ = new Subject<string>();

  /** Dependencies */
  private requestService = inject(RequestService);

  /**
   * Send workshop review
   */
  public sendWorkshopReview(review: ReviewBody): Observable<UserApiResponse> {
    const url = 'sendWorkshopReview';
    return this.requestService.post(url, review);
  }

  /**
   * Create new user client by workshop
   */
  public createClientByWorkshop(user: SignUpBody, vehicle: VehicleBody): Observable<UserApiResponse> {
    const url = 'client';
    return this.requestService.post(url, { user, vehicle });
  }

  /**
   * Update client by workshop
   */
  public updateClientByWorkshop(form: UserUpdate, clientId: string): Observable<UserApiResponse> {
    const url = `client/${clientId}`;
    return this.requestService.put(url, form);
  }

  /**
   * Delete client by workshop
   */
  public deleteClientByWorkshop(clientId: string) {
    const url = `client/${clientId}`;
    return this.requestService.delete(url);
  }

  /**
   * Verify maintenance
   */
  public verifyMaintenance(maintenanceId: string): Observable<UserApiResponse> {
    const url = `maintenance/${maintenanceId}/verifyByWorkshop`;
    return this.requestService.put(url, {});
  }

  /**
   * Unverify maintenance
   */
  public unverifyMaintenance(maintenanceId: string): Observable<UserApiResponse> {
    const url = `maintenance/${maintenanceId}/unverifyByWorkshop`;
    return this.requestService.put(url, {});
  }

  /**
   * Generate maintenance invoice
   */
  public generateMaintenanceInvoice(maintenanceId: string, invoiceNumber: string, invoiceFile: string): Observable<UserApiResponse> {
    const url = `maintenance/${maintenanceId}/saveGeneratedMaintenanceInvoice`;
    return this.requestService.post(url, { invoiceNumber, invoiceFile });
  }

  /**
   * Fetch workshop landing page
   */
  public fetchWorkshopLandingPage(username: string): Observable<WorkshopLandingPageApiResponse> {
    const url = `workshopLandingPage/${username}`;
    return this.requestService.get(url, {}, true);
  }

  /**
   * Create stock item
   */
  public createStockItem(stockItem: StockItemBody): Observable<UserApiResponse> {
    const url = 'stockItem';
    return this.requestService.post(url, stockItem);
  }

  /**
   * Update stock item
   */
  public updateStockItem(stockItemId: string, stockItem: StockItemBody): Observable<UserApiResponse> {
    const url = `stockItem/${stockItemId}`;
    return this.requestService.put(url, stockItem);
  }

  /**
   * Delete stock item
   */
  public deleteStockItem(stockItemId: string): Observable<UserApiResponse> {
    const url = `stockItem/${stockItemId}`;
    return this.requestService.delete(url);
  }

  /**
   * Create stock item transaction
   */
  public createStockItemTransaction(stockItemId: string, transaction: StockItemTransactionBody): Observable<UserApiResponse> {
    const url = `stockItem/${stockItemId}/transaction`;
    return this.requestService.post(url, transaction);
  }

  /**
   * Update stock item transaction
   */
  public updateStockItemTransaction(stockItemId: string, transactionId: string, transaction: StockItemTransactionBody): Observable<UserApiResponse> {
    const url = `stockItem/${stockItemId}/transaction/${transactionId}`;
    return this.requestService.put(url, transaction);
  }

  /**
   * Delete stock item transaction
   */
  public deleteStockItemTransaction(stockItemId: string, transactionId: string): Observable<UserApiResponse> {
    const url = `stockItem/${stockItemId}/transaction/${transactionId}`;
    return this.requestService.delete(url);
  }

}